import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import ProductsGrid from "../components/productsGrid";

export default function Template({ data }) {
  const { markdownRemark, allProducts } = data;
  const { frontmatter, html } = markdownRemark;
  const { edges: products } = allProducts;

  const breadcrumbs = [
    { name: "Home", href: "/" },
    { name: frontmatter.title }
  ];
  return (
    <Layout
      breadcrumbs={breadcrumbs}
      pageTitle={frontmatter.title}
      preambleText={frontmatter.preambleText}
      metaDescription={frontmatter.metaDescription}
    >
      <div dangerouslySetInnerHTML={{ __html: html }} />
      <ProductsGrid products={products} />
    </Layout>
  );
}

export const shopHomeQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        preambleText
        metaDescription
      }
    }
    allProducts: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "shopCategoryHome" } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
